import axios from "axios";

const endpointUrl = `${process.env.VUE_APP_API_AI_ASSISTANCE}`;

const extractData = (response) => response.data;

export const getAISuggestions = (id) =>
  axios.get(`${endpointUrl}/cases/${id}`).then(extractData);

export const getAIFilters = (body) =>
  axios
    .post(`${endpointUrl}/cases/filter`, { input_string: body })
    .then(extractData);
