<template>
  <v-dialog
    :value="dialog"
    :fullscreen="modalMobileView"
    :style="!modalMobileView ? 'z-index: 20001;' : ''"
    width="616"
    persistent
    class="text-modal"
  >
    <v-card class="pa-5 text-modal__content">
      <v-card-title class="text-h5 lighten-2 pa-0">
        {{ title }}
      </v-card-title>
      <v-card-text class="pa-0">
        {{ description }}
      </v-card-text>

      <v-textarea
        :value="text"
        :height="height"
        :disabled="readOnly"
        :label="textPlaceholder"
        :auto-grow="autoGrow"
        hide-details
        solo
        no-resize
        class="pt-4"
        @input="handleTextAreaInput"
      />

      <slot name="append" />

      <v-card-actions
        class="text-modal__content--actions pa-0 pt-4"
        :class="btnContainerClass"
      >
        <v-btn
          v-show="!readOnly"
          :loading="loading"
          :disabled="isConfirmButtonDisabled"
          :min-width="modalMobileView ? '100%' : 'auto'"
          color="primary"
          rounded
          normal
          variant="elevated"
          class="mb-md-0 mb-3"
          @click="handleModalSendClick"
        >
          {{ actionButtons[1] }}
        </v-btn>
        <v-btn
          :disabled="loading"
          :min-width="modalMobileView ? '100%' : 'auto'"
          color="primary"
          text
          class="mb-md-0 mb-3"
          @click="$emit('cancel')"
        >
          {{ actionButtons[0] }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TextModal",
  props: {
    dialog: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    initialValue: {
      type: String,
      default: "",
    },
    actionButtons: {
      type: Array,
      default: () => ["cancel", "send"],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    textPlaceholder: {
      type: String,
      default: "Enter your reply",
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disableWithoutChanges: {
      type: Boolean,
      default: true,
    },
    autoGrow: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number || String,
      default: "100%",
    },
    toggleAfterSubmit: {
      type: Boolean,
      default: true,
    },
    clearOnSubmit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      text: this.initialValue,
    };
  },

  computed: {
    isConfirmButtonDisabled() {
      if (!this.disableWithoutChanges) return false;
      return this.initialValue === this.text || !this.text.trim().length;
    },
    modalMobileView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    btnContainerClass() {
      return {
        "flex-row-reverse": !this.modalMobileView,
        "flex-column": this.modalMobileView,
        "justify-start": !this.modalMobileView,
      };
    },
  },

  watch: {
    initialValue(newData) {
      this.text = newData;
    },
  },

  methods: {
    handleTextAreaInput(value) {
      this.text = value;
    },
    handleModalSendClick() {
      this.$emit("send", this.text.trim());
      if (this.clearOnSubmit) {
        this.text = "";
      }
      this.toggleAfterSubmit && this.$emit("toggle", !this.dialog);
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/main.scss";

.v-dialog--fullscreen > .text-modal {
  @media (max-width: 390px) {
    &__content {
      padding-top: calc(#{$headerHeight} + 24px) !important;
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }
}
</style>
