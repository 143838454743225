var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"AuditEvaluation overflow-x-hidden",attrs:{"color":"#EBFAFB","flat":""}},[_c('v-card-title',{staticClass:"pb-0"},[_vm._v("Audit")]),_c('v-card-text',[(!_vm.isGeneralType)?_c('p',[_vm._v(" Please answer the audit questions below, and the case status will be changed automatically. ")]):_vm._e()]),_vm._l((_vm.sections),function(section){return _c('div',{key:_vm.get(section, '_id', ''),staticClass:"AuditEvaluation-Section"},[_c('h3',{staticClass:"px-5 py-3 text-subtitle-2 font-weight-bold text-capitalize"},[_vm._v(" "+_vm._s(_vm.get(section, "title", ""))+" ")]),_vm._l((section.criteria),function(item){return _c('div',{key:item._id,staticClass:"px-3 py-0 my-4"},[_c('v-tooltip',{attrs:{"disabled":_vm.isGeneralTooltipDisabled &&
          !_vm.checkForSuggestionConfidence(_vm.suggestions, item),"start":"","right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-radio-group',{staticClass:"Criteria text--primary",attrs:{"value":_vm.get(_vm.value, _vm.valuePath(item._id), null),"disabled":_vm.disabled,"row":"","hide-details":""},on:{"change":function($event){return _vm.handleChange($event, item._id)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text--primary font-weight-bold",class:_vm.shouldShowWarningColor(item?._id) ? 'text-warn' : ''},[_vm._v(" "+_vm._s(_vm.get(item, "text", ""))+" ")])]},proxy:true}],null,true)},[(_vm.isTargetType)?[_c('v-radio',{attrs:{"label":"Yes","value":true}}),_c('v-radio',{attrs:{"label":"No","value":false}})]:_vm._e(),(_vm.isScoreType)?[_c('v-radio',{class:_vm.checkForSuggestionValue(_vm.suggestions, item) == '0'
                      ? 'test pr-2'
                      : 'pr-2',attrs:{"label":"0","value":0}}),_c('v-radio',{class:_vm.checkForSuggestionValue(_vm.suggestions, item) == '1'
                      ? 'test pr-2'
                      : 'pr-2',attrs:{"label":"1","value":1}}),_c('v-radio',{class:_vm.checkForSuggestionValue(_vm.suggestions, item) == '2'
                      ? 'test pr-2'
                      : 'pr-2',attrs:{"label":"2","value":2}}),_c('v-radio',{class:_vm.checkForSuggestionValue(_vm.suggestions, item) == 'NA'
                      ? 'test pr-2'
                      : 'pr-2',attrs:{"label":"N/A","value":-1}})]:_vm._e(),(_vm.isGeneralType)?_vm._l((_vm.onlyRadioOptions(
                    item.options
                  )),function(auditOption,index){return _c('v-radio',{key:index,attrs:{"label":auditOption,"value":_vm.GeneralAuditOptionMapping[auditOption]}})}):_vm._e()],2),(
                _vm.isGeneralType &&
                item?.options?.includes(_vm.GeneralAuditOptions.comment)
              )?_c('v-text-field',{staticClass:"ml-5",attrs:{"disabled":_vm.disabled,"label":"Comment","value":_vm.get(_vm.value, _vm.commentsPath(item._id), null)},on:{"change":function($event){return _vm.handleChange($event, item._id)}}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isClinicalDoc ? `AI confidence estimate: ${_vm.checkForSuggestionConfidence( _vm.suggestions, item )}` : "Start Audit to be able to answer."))])])],1)})],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }